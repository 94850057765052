import React, {useState} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'


const Resource = props => {
    return (
        <div className='resource' id='resource'>
            <h2>Conheça os principais recursos que
                vão facilitar sua vida!</h2>

            <div className='resources-items-lg'>
                <div className='resource-item'>
                    <AnchorLink href='#medical-record'>
                        <div className='resource-title'>
                            <div className='medical-record-img resource-img'></div>
                            <h3>Prontuário<br/> Eletrônico</h3>
                        </div>
                    </AnchorLink>
                    <p>Com prontuário se torna mais fácil gerenciar o <b>histórico</b> de seus clientes e ainda
                        anexar <b>arquivos</b> importantes.</p>
                </div>

                <div className='resource-item'>
                    <AnchorLink href='#calendar'>
                        <div className='resource-title'>
                            <div className='calendar-img resource-img'></div>
                            <h3>Agenda
                                <br/>
                                {' '}
                            </h3>
                        </div>
                    </AnchorLink>
                    <p><b>Organize</b> sua agenda de forma descomplicada gerenciando seus <b>múltiplos</b> locais de
                        atendimento. </p>
                </div>

                <div className='resource-item'>
                    <AnchorLink href='#chat'>
                        <div className='resource-title'>
                            <div className='chat-img resource-img'></div>
                            <h3>Chat <br/> Integrado</h3>
                        </div>
                    </AnchorLink>
                    <p>Para uma <b>comunicação</b> mais eficiente e <b>discreta</b>, com observações importante sobre
                        seus pacientes.</p>
                </div>

                <div className='resource-item'>
                    <AnchorLink href='#documents'>
                        <div className='resource-title'>
                            <div className='documents-img resource-img'></div>
                            <h3>Módulo<br/> Documentos</h3>
                        </div>
                    </AnchorLink>
                    <p><b>Organize</b> todos os seus receituários, orçamentos e contratos/termos de forma prática
                        e <b>rápida</b>. </p>
                </div>

                <div className='resource-item'>
                    <AnchorLink href='#security'>
                        <div className='resource-title'>
                            <div className='security-img resource-img'></div>
                            <h3>Segurança <br/>dos Dados</h3>
                        </div>
                    </AnchorLink>
                    <p>Suas <b>informações</b> e dos seus clientes salvas de forma <b>segura</b> e acessíveis onde você
                        estiver.</p>
                </div>
            </div>

            <div className='resources-items-sm'>
                <div className='resource-item'>
                    <AnchorLink href='#medical-record'>
                        <div className='resource-title'>
                            <div className='medical-record-img resource-img'></div>
                        </div>
                    </AnchorLink>
                    <h3>Prontuário Eletrônico</h3>
                    <p>Com prontuário se torna mais fácil gerenciar o <b>histórico</b> de seus clientes e ainda
                        anexar <b>arquivos</b> importantes.</p>
                </div>

                <div className='resource-item'>
                    <AnchorLink href='#calendar'>
                        <div className='resource-title'>
                            <div className='calendar-img resource-img'></div>
                        </div>
                    </AnchorLink>
                    <h3>Agenda</h3>
                    <p><b>Organize</b> sua agenda de forma descomplicada gerenciando seus <b>múltiplos</b> locais de
                        atendimento. </p>
                </div>

                <div className='resource-item'>
                    <AnchorLink href='#chat'>
                        <div className='resource-title'>
                            <div className='chat-img resource-img'></div>
                        </div>
                    </AnchorLink>
                    <h3>Chat Integrado</h3>
                    <p>Para uma <b>comunicação</b> mais eficiente e <b>discreta</b>, com observações importante sobre
                        seus pacientes.</p>
                </div>

                <div className='resource-item'>
                    <AnchorLink href='#documents'>
                        <div className='resource-title'>
                            <div className='documents-img resource-img'></div>
                        </div>
                    </AnchorLink>
                    <h3>Módulo Documentos</h3>
                    <p><b>Organize</b> todos os seus receituários, orçamentos e contratos/termos de forma prática
                        e <b>rápida</b>. </p>
                </div>

                <div className='resource-item'>
                    <AnchorLink href='#security'>
                        <div className='resource-title'>
                            <div className='security-img resource-img'></div>
                        </div>
                    </AnchorLink>
                    <h3>Segurança dos Dados</h3>
                    <p>Suas <b>informações</b> e dos seus clientes salvas de forma <b>segura</b> e acessíveis onde você
                        estiver.</p>
                </div>


            </div>
        </div>
    )
}

export default Resource;

