import React, {useState, useEffect} from 'react';
import MaskedInput from "react-text-mask";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTransition, animated } from 'react-spring'

const Invitation = props => {
    const [houmedLogo] = useState('https://houmed-public.s3.amazonaws.com/identidade-visual/Logotipo+Branco.png');
    const [invitation, setInvitation] = useState({
        full_name: '',
        email: props.email,
        speciality: '',
        register_initials: '',
        register_state: '',
        register_number: '',
        answer: false,
        answer_text: '',
        ref_code: '',
    });
    const [validation, setValidation] = useState({
        full_name: true,
        email: true,
        speciality: true,
        register_initials: true,
        register_state: true,
        register_number: true,
        answer: true,
        answer_text: true,
    });
    const [sending, setSending] = useState(false);
    const [sended, setSended] = useState(false);
    const [hasFormError, setHasFormError] = useState(false);
    const [formError, setFormError] = useState('');
    const [authenticityToken] = useState(document.querySelector('meta[name="csrf-token"]').getAttribute('content'));

    useEffect(() => {
        let paramsStr = location.search.split('?')[1];
        let params = {};
        if (paramsStr) {
            paramsStr.split('&').forEach((param) => {
                params[`${param.split('=')[0]}`] = param.split('=')[1]
            });
        }
        if (params.ref && params.ref != '') {
            document.querySelector('#ref-code').setAttribute('disabled', 'disabled');
            setInvitation({
                ...invitation,
                ref_code: params.ref,
            });
        }

        axios
            .post(`/associados/periodos/${params.ref}/atualizar.json`, {
                authenticity_token: authenticityToken,
                count: 'access',
            });
    }, []);

    const genericFieldHandler = e => {
        const copyOnInvitation = {...invitation};
        if (e.target.getAttribute('data-name') === 'register_state' || e.target.getAttribute('data-name') === 'register_initials' || e.target.getAttribute('data-name') === 'ref_code') {
            copyOnInvitation[e.target.getAttribute('data-name')] = e.target.value.toUpperCase();
        } else {
            copyOnInvitation[e.target.getAttribute('data-name')] = e.target.value;
        }
        setInvitation(copyOnInvitation);
    }

    const checkboxHandler = e => {
        if (e.target.getAttribute('data-name') === 'checkbox-yes') {
            setInvitation({
                ...invitation,
                answer: true,
            });
        } else {
            setInvitation({
                ...invitation,
                answer: false,
            });
        }
    }

    const submitInvitation = e => {
        if (sending) {
            return
        }

        setValidation({...validation, full_name: true,});
        if (invitation.full_name.length < 3) {
            setValidation({...validation, full_name: false});
            return
        }

        setValidation({...validation, full_name: true, email: true});
        if (invitation.email.length < 3) {
            setValidation({...validation, full_name: true, email: false});
            return
        }

        setValidation({...validation, full_name: true, email: true, speciality: true,});
        if (invitation.speciality.length < 3) {
            setValidation({...validation, full_name: true, email: true, speciality: false,});
            return
        }

        setValidation({...validation, full_name: true, email: true, speciality: true, register_initials: true,});
        if (invitation.register_initials.length < 1) {
            setValidation({...validation, full_name: true, email: true, speciality: true, register_initials: false,});
            return
        }

        setValidation({
            ...validation,
            full_name: true,
            email: true,
            speciality: true,
            register_initials: true,
            register_state: true
        });
        if (invitation.register_state.length < 2) {
            setValidation({
                ...validation,
                full_name: true,
                email: true,
                speciality: true,
                register_initials: true,
                register_state: false
            });
            return
        }

        setValidation({
            ...validation,
            full_name: true,
            email: true,
            speciality: true,
            register_initials: true,
            register_state: true,
            register_number: true
        });
        if (invitation.register_number.length < 1) {
            setValidation({
                ...validation,
                full_name: true,
                email: true,
                speciality: true,
                register_initials: true,
                register_state: true,
                register_number: false,
            });
            return
        }

        setValidation({
            ...validation,
            full_name: true,
            email: true,
            speciality: true,
            register_initials: true,
            register_state: true,
            register_number: true,
            answer_text: true,
        });
        if (invitation.answer && invitation.answer_text.length < 2) {
            setValidation({
                ...validation,
                full_name: true,
                email: true,
                speciality: true,
                register_initials: true,
                register_state: true,
                register_number: true,
                answer_text: false,
            });
            return
        }

        setSending(true);
        setHasFormError(false);
        setFormError('');
        axios
            .post(`/associados/periodos/${invitation.ref_code}/atualizar.json`, {
                authenticity_token: authenticityToken,
                count: 'sign_up',
            });
        axios
            .post('/solicitar-convite', {
                authenticity_token: authenticityToken,
                home: invitation
            }, {headers: {'content-type': 'application/json', 'Accept': 'application/json'}})
            .then(res => {
                if (res.data.status === 'success') {
                    setSended(true);
                } else {
                    setSended(false);
                    setHasFormError(true);
                    setFormError(res.data.message);
                }
                setSending(false);
            });
    }

    const transitions = useTransition(location, location => location.pathname, {
        from:  { opacity: 0, left: '25%' },
        enter: { opacity: 1, left: '0%'},
        leave: {opacity: 0, left: '-25%' },
    });

    const transitionComponent = transitions.map(({ item: location, props, key }) => (
        <animated.div key={key} style={props} className='right-side'>
            <div className={['right-side-content', sended ? 'd-none' : ''].join(' ')}>
                <div className='img-logo-mobile'>
                    <img src='https://houmed-public.s3.amazonaws.com/identidade-visual/Logotipo+Colorido.png' />
                </div>
                <h1>Solicite sua conta!</h1>
                <p>Informe alguns dados para receber a abertura da sua conta no seu e-mail!</p>
                <div className='invitation-form-group'>
                    <input type='text'
                           className={['form-control', 'invitation-field', validation.full_name ? '' : 'wrong'].join(' ')}
                           data-name='full_name'
                           onChange={genericFieldHandler}
                           value={invitation.full_name}
                           placeholder='Nome Completo *'/>
                    <p
                        className={['info-warning', validation.full_name ? 'd-none' : ''].join(' ')}
                    >
                        Verifique novamente
                    </p>
                </div>

                <div className='invitation-form-group'>
                    <input type='email'
                           className={['form-control', 'invitation-field', validation.email ? '' : 'wrong'].join(' ')}
                           data-name='email'
                           onChange={genericFieldHandler}
                           value={invitation.email}
                           placeholder='Email *'/>
                    <p
                        className={['info-warning', validation.email ? 'd-none' : ''].join(' ')}
                    >
                        Verifique novamente
                    </p>
                </div>

                <div className='invitation-form-group'>
                    <input type='text'
                           className={['form-control', 'invitation-field', validation.speciality ? '' : 'wrong'].join(' ')}
                           data-name='speciality'
                           onChange={genericFieldHandler}
                           value={invitation.speciality}
                           placeholder='Especialidade *'/>
                    <p
                        className={['info-warning', validation.speciality ? 'd-none' : ''].join(' ')}
                    >
                        Verifique novamente
                    </p>
                </div>
                <p className='inner-text'>Vamos solicitar sua sigla e número de registro Ex: CRM SP 1234. Se na sua
                    especialidade não há registro deixe em branco. </p>
                <div className='d-flex' style={{maxWidth: '420px'}}>
                    <div className='invitation-form-group mr-12'>
                        <input type='text'
                               className={['form-control', 'invitation-field', validation.register_initials ? '' : 'wrong'].join(' ')}
                               onChange={genericFieldHandler}
                               data-name='register_initials'
                               value={invitation.register_initials}
                               placeholder='Sigla *'/>
                        <p
                            className={['info-warning', validation.register_initials ? 'd-none' : ''].join(' ')}
                        >
                            Verifique novamente
                        </p>
                    </div>
                    <div className='invitation-form-group mr-12'>
                        <MaskedInput
                            mask={[/[A-Za-z]/, /[A-Za-z]/]}
                            className={['form-control', 'invitation-field', validation.register_state ? '' : 'wrong'].join(' ')}
                            placeholder='Estado *'
                            guide={false}
                            onChange={genericFieldHandler}
                            data-name='register_state'
                            value={invitation.register_state}
                        />
                        <p
                            className={['info-warning', validation.register_state ? 'd-none' : ''].join(' ')}
                        >
                            Verifique novamente
                        </p>
                    </div>
                    <div className='invitation-form-group'>
                        <MaskedInput
                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                            className={['form-control', 'invitation-field', validation.register_number ? '' : 'wrong'].join(' ')}
                            onChange={genericFieldHandler}
                            placeholder='Número *'
                            guide={false}
                            data-name='register_number'
                            value={invitation.register_number}
                        />
                        <p
                            className={['info-warning', validation.register_number ? 'd-none' : ''].join(' ')}
                        >
                            Verifique novamente
                        </p>
                    </div>
                </div>

                <p className='inner-text'>Usa outra solução para a gestão de seus clientes ou pacientes?</p>
                <div className='invitation-form-group'>
                    <div className='d-flex'>
                        <input type='checkbox'
                               className={['form-control', 'invitation-field'].join(' ')}
                               data-name='checkbox-yes'
                               id='yes'
                               checked={invitation.answer}
                               onChange={checkboxHandler}/> <label style={{cursor: "pointer"}}
                                                                   className='d-flex mr-5' htmlFor='yes'>Sim</label>
                        <input type='checkbox'
                               className={['form-control', 'invitation-field'].join(' ')}
                               id='no'
                               data-name='checkbox-no'
                               checked={!invitation.answer}
                               onChange={checkboxHandler}/> <label style={{cursor: "pointer"}}
                                                                   className='d-flex' htmlFor='no'>Não</label>
                    </div>
                </div>
                <div className='invitation-form-group'>
                    <input type='text'
                           className={['form-control', 'invitation-field', invitation.answer ? '' : 'd-none', validation.answer_text ? '' : 'wrong'].join((' '))}
                           placeholder='Nome da solução'
                           data-name='answer_text' value={invitation.answer_text} onChange={genericFieldHandler}/>
                    <p
                        className={['info-warning', validation.answer_text ? 'd-none' : ''].join(' ')}
                    >
                        Verifique novamente
                    </p>
                </div>
                <p className='inner-text'>Código de Indicação. </p>
                <div className='invitation-form-group'>
                    <MaskedInput
                        mask={[/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/,]}
                        id='ref-code'
                        className={['form-control', 'invitation-field'].join(' ')}
                        onChange={genericFieldHandler}
                        placeholder='Indicação (opcional)'
                        guide={false}
                        data-name='ref_code'
                        value={invitation.ref_code}
                    />
                    <p
                        className={['info-warning', validation.register_number ? 'd-none' : ''].join(' ')}
                    >
                        Verifique novamente
                    </p>
                </div>
                <p className='inner-text'>* Campos obrigatórios. </p>
                <button className='btn doc-secondary-btn'
                        style={{width: '100%', maxWidth: '420px'}} onClick={submitInvitation}>CRIAR CONTA
                </button>
                <p
                    className={['info-warning', hasFormError ? '' : 'd-none'].join(' ')}
                >
                    {formError}
                </p>
                <p className='copyright'>
                    Houmed®<br/>
                    Todos os Direitos Reservados
                </p>
            </div>

            <div className={['right-side-content', sended ? '' : 'd-none'].join(' ')}>
                <h1>Agora é só conferir o sua caixa de e-mails 😉</h1>
                <p style={{maxWidth: '339.68px', width: '100%'}}>Ficamos felizes por se juntar a nós! Agora é só acessar sua caixa de e-mails e conferir os dados da sua nova conta. </p>
                <Link to='/' onClick={props.clearEmailField}><button className='btn doc-primary-btn' style={{maxWidth: '418px', width: '100%'}}>Voltar</button></Link>

            </div>
        </animated.div>
    ));

    return (
        <div className='invitation'>
            <div className='left-side'>
                <img src={houmedLogo}/>
            </div>
            {transitionComponent}
        </div>
    )
}

export default Invitation