import React, {useState} from "react";
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import AnchorLink from 'react-anchor-link-smooth-scroll'


const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const MyDrawer = props => {
    const [professionalIcon] = useState("/assets/web/v1/icones/professional-icon.svg");
    const [patientIcon] = useState("/assets/web/v1/icones/patient-icon.svg");
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem>
                    <ListItemIcon></ListItemIcon>
                    <AnchorLink href="#resource">
                        <ListItemText primary='Funcionalidades' className='doc-drawer-nav-item'/>
                    </AnchorLink>
                </ListItem>
                <ListItem>
                    <ListItemIcon></ListItemIcon>
                    <AnchorLink href="#plans">
                        <ListItemText primary='Planos' className='doc-drawer-nav-item'/>
                    </AnchorLink>
                </ListItem>
            </List>
            <Divider/>
            <List>
                <a href='/profissionais'>
                    <ListItem>
                        <ListItemIcon><img src={professionalIcon}/></ListItemIcon>
                        <ListItemText primary='Profissionais' className='doc-drawer-nav-item-professional'/>
                    </ListItem>
                </a>
                <a href='/usuarios'>
                    <ListItem>
                        <ListItemIcon><img src={patientIcon}/></ListItemIcon>
                        <ListItemText primary='Pacientes' className='doc-drawer-nav-item-patient'/>
                    </ListItem>
                </a>
            </List>
        </div>
    );

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const el = ['right'].map((anchor) => (
        <React.Fragment key={anchor}>
            <button onClick={toggleDrawer(anchor, true)} className='menu-btn'>Menu</button>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
            </Drawer>
        </React.Fragment>
    ))

    return el;
}
export default MyDrawer