import React, {useState} from "react";
import AnchorLinks from "../../../ui/AnchorLinks/anchorLinks";

const Security = props => {
    const [securityImg] = useState('/assets/web/v1/images/security.svg');
    const [securityIcon] = useState('/assets/web/v1/icones/security-icon-0.svg');
    const [securityIconDark] = useState('/assets/web/v1/icones/security-icon-0-dark.svg');
    return (
        <div className='security' id='security'>
            <div className='home-grid image-right home-grid-lg'>
                <div className='content'>
                    <p className='info'>Segurança <img src={props.isDark ? securityIconDark : securityIcon} align='Módulo Documentos'/></p>
                    <h2>Melhor opção em<br/> infraestrutura de segurança!</h2>
                    <p className='content-paragraph'>Pensando na segurança dos profissinais e seus clientes e pacientes,
                        todos os dados disponibilizados em nossa plataforma, passam pelos protocolos mais avançados de
                        segurança da AWS (Amazon Web Service) garantindo assim, a melhor infraestrututa em
                        segurança.</p>
                    <AnchorLinks
                        medicalRecord={true}
                        calendar={true}
                        chat={true}
                        documents={true}
                        security={false}
                        align='left'
                    />
                </div>
                <img src={securityImg} align='Segurança'/>
            </div>
            <div className='home-grid home-grid-sm'>
                <img src='/assets/web/v1/images/security-sm.svg' />
                <p className='info'>Segurança</p>
                <h2>Melhor opção em infraestrutura de segurança!</h2>
                <p className='content-paragraph'>
                    Pensando na segurança dos profissionais, seus clientes e pacientes, todos os dados são
                    disponibilizados em nossa plataforma através de protocolos avançados de segurança
                    da AWS (Amazon Web Service) garantindo assim, a melhor infraestrututa em segurança.
                </p>
                <div className='anchor-links'>
                    <AnchorLinks
                        medicalRecord={true}
                        calendar={true}
                        chat={true}
                        documents={true}
                        security={false}
                        align='right'
                    />
                </div>
            </div>
        </div>
    )
}

export default Security