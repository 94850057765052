import React, {useState} from "react";
import Navigation from "./navigation/navigation";
import AnchorLinks from "../../../ui/AnchorLinks/anchorLinks";
import {Link} from 'react-router-dom';

const Header = (props) => {
    const [marilhinha] = useState('/assets/web/v1/images/account-verification.svg');

    const frameStyle = {
        maxWidth: '600px',
        background: '#FFFFFF',
        borderRadius: '25px',
        padding: '38px',
        filter: 'drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.04)), drop-shadow(0px 4px 56px rgba(0, 0, 0, 0.04))',
    }

    const h1Style = {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '44px',
        textAlign: 'center',
        color: '#03386D',
    }

    const imgStyle = {
        width: '200px',
        height: '200px',
        margin: '0 auto',
        display: 'flex',
    }

    const pStyle = {
        minHeight: 'unset',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#00567C',

    }
    return (
        <div className="header">
            <Navigation/>
            <div className={['header-content', location.search.includes('conta=confirmada') ? 'd-none' : ''].join(' ')} onClick={props.isDarkHandler}>
                <h1>
                    Plataforma intuitiva para profissionais da saúde
                </h1>
                <p>Os principais recursos e funções usadas diariamente por milhares de profissionais da saúde, agora
                    estão disponiveis para você, de uma forma muito mais fácil e descomplicada.</p>
                <div className='form-group d-flex header-actions-lg'>
                    <input type='text' className='form-control' placeholder='Insira seu e-mail' value={props.email}
                           onChange={props.emailChangeHandler}/>
                    <Link to='/solicitar-convite'>
                        <button className='btn doc-secondary-btn'>CRIAR CONTA</button>
                    </Link>
                </div>
                <div className='form-group d-flex header-actions-sm'>
                    <Link to='/solicitar-convite'>
                        <button className='btn doc-secondary-btn'>CRIAR CONTA</button>
                    </Link>
                </div>
                <div className='mt-5 header-anchors'>
                    <AnchorLinks
                        medicalRecord={true}
                        calendar={true}
                        chat={true}
                        documents={true}
                        security={true}
                        align='left'
                    />
                </div>
            </div>
            <div className={['header-content', location.search.includes('conta=confirmada') ? '' : 'd-none'].join(' ')} style={{paddingLeft: '5%', paddingRight: '5%'}}>
                <div className='frame' style={frameStyle}>
                    <h1 style={h1Style}>
                        Parabéns!<br/>Seu convite foi confirmado com sucesso.
                    </h1>
                    <img src={marilhinha} style={imgStyle}/>
                    <p style={pStyle}>Enviamos agorinha, por e-mail, seus dados para o seu primeiro acesso. Corre lá e
                        confere! </p>
                </div>
            </div>
        </div>
    );
};

export default Header;
