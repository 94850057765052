import React from "react";

const Footer = props => {
    return (
        <div className='footer'>
            <p>
                <span className='name'>Houmed ®</span>
                <span className='rights'>Todos os Direitos Reservados {new Date().getFullYear()}</span>
            </p>
            <p className='d-flex justify-content-between footer-most-right'>
                <span className='copy'>
                    app.houmed.com.br
                </span>
                <span className='social-media'>
                    <a target='_blank' rel='nofollow' href={"https://www.instagram.com/"}><img  src='/assets/web/v1/icones/instagram-grey.svg'/></a>
                    <a target='_blank' rel='nofollow' href={"https://www.twitter.com/"}><img  src='/assets/web/v1/icones/twitter-grey.svg'/></a>
                    <a target='_blank' rel='nofollow' href={"https://www.facebook.com/"}><img src='/assets/web/v1/icones/facebook-grey.svg'/></a>
                </span>
            </p>
        </div>
    )
}

export default Footer