import React, {useState} from "react";
import AnchorLinks from "../../../ui/AnchorLinks/anchorLinks";

const Chat = props => {
    const [chatImg] = useState('/assets/web/v1/images/chat-home-img.svg');
    const [chatIcon] = useState('/assets/web/v1/icones/chat-icon-0.svg');
    const [chatIconDark] = useState('/assets/web/v1/icones/chat-icon-0-dark.svg');
    return (
        <div className='chat' id='chat'>
            <div className='home-grid image-right home-grid-lg'>
                <div className='content'>
                    <p className='info'><img src={props.isDark ? chatIconDark : chatIcon} align='Chat'/> Chat Integrado</p>
                    <h2>Comunicação importante na<br/> hora do atendimento!</h2>
                    <p className='content-paragraph'>Disponibilizamos um chat eficiênte e rápido que facilita a
                        comunição interna com os seus colaboradores.
                        Com a função exclusiva de acesso rápido a importantes observações, dentro do chat, seu
                        atendimento conta com uma maior eficiência e humanização.</p>
                    <AnchorLinks
                        medicalRecord={true}
                        calendar={true}
                        chat={false}
                        documents={true}
                        security={true}
                        align='left'
                    />
                </div>
                <img src={chatImg} align='Chat'/>

            </div>
            <div className='home-grid home-grid-sm'>
                <img src='/assets/web/v1/images/chat-home-img-sm.svg' />
                <p className='info'>Chat Integrado</p>
                <h2>Comunicação importante na hora do atendimento!</h2>
                <p className='content-paragraph'>
                    Disponibilizamos um chat eficiente e rápido que facilita a comunição interna com os seus colaboradores.
                    Com a função exclusiva de acesso rápido a importantes observações, dentro do chat,
                    seu atendimento conta com uma maior qualidade e humanização.
                </p>
                <div className='anchor-links'>
                    <AnchorLinks
                        medicalRecord={true}
                        calendar={true}
                        chat={false}
                        documents={true}
                        security={true}
                        align='right'
                    />
                </div>
            </div>
        </div>
    )
}

export default Chat