import React, { useState } from "react";
import Home from "./components/home/home";
import Invitation from "./components/invitation/invitation";
import { Route, Switch, Redirect } from 'react-router-dom';


const App = (props) => {
    const [email, setEmail] = useState('')
    const emailChangeHandler = e => {
        setEmail(e.target.value);
    }

    const clearEmailField = () => {
        setEmail('');
    }
    return (
        <Switch>
            <Route path='/' exact>
                <Home email={email} emailChangeHandler={emailChangeHandler}/>
            </Route>
            <Route path='/solicitar-convite' exact>
                <Invitation
                    email={email}
                    clearEmailField={clearEmailField}
                />
            </Route>

            <Redirect to='/' />
        </Switch>
    );
};

export default App;
