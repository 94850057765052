import React, {useEffect, useState} from "react";

let deferredPrompt;
const PwaInstall = props => {
    const [installable, setInstallable] = useState(false);
    const [isApple, setIsApple] = useState(false);

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            // Update UI notify the user they can install the PWA
            setInstallable(true);
        });

        window.addEventListener('appinstalled', () => {
            // Log install to analytics
            console.log('INSTALL: Success');
        });

        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode()) {
            setIsApple(true);
        }
    }, []);

    useEffect(() => {
        if (isApple) {
            setTimeout(() => setIsApple(false), 20000);
        }
    }, [isApple]);

    const handleInstallClick = (e) => {
        // Hide the app provided install promotion
        setInstallable(false);
        // Show the install prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the install prompt');
            } else {
                console.log('User dismissed the install prompt');
            }
        });
    }

    // Detects if device is on iOS
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    }

    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    return installable ? <div className='cookie-modal row'>
        <p className="modal-cookie-text">Gostaria de adicionar o <b>Houmed®</b> no seu dispositivo? </p>
        <button className="btn modal-cookie-button" type="button" id="modal-cookie-buttom"
                onClick={handleInstallClick}>Sim
        </button>
    </div> : isApple ? <div className='apple-installer'>
        <p className="modal-cookie-text">Para adicionar o <b>Houmed®</b>, aperte no ícone abaixo e depois <b>"Adicione à Tela de Início"</b>.</p>
    </div> : null
}

export default PwaInstall