import React, {useState} from 'react';

const About = props => {
    const [peopleOfHoumed] = useState('/assets/web/v1/images/people-of-docnidus.svg')
    const [publicPeople] = useState('/assets/web/v1/images/public-people.svg')
    const [desktop] = useState('/assets/web/v1/images/desktop.svg')
    return (
        <div className='about'>
            <h2>Desenvolvemos sempre em parceria com os profissionais da saúde!</h2>
            <div className='connections-lg'>
                <img className='people' src={peopleOfHoumed}/>
                <span className='dashed'></span>
                <img className='logo' src={desktop}/>
                <span className='dashed'></span>
                <img className='people' src={publicPeople}/>
            </div>
            <div className='connections-sm'>
                <img className='people' src='/assets/web/v1/images/public-people-sm.svg'/>
            </div>
            <p className='subtitle'>Esta é a garantia de termos uma plataforma sempre atualizada!</p>
            <p className='info'>Com uma equipe de engenheiros, médicos e profissionais da saúde, estaremos sempre desenvolvendo novos
                recursos, garantindo a melhor experiência de atendimento aos clientes e pacientes.</p>

        </div>)
}

export default About