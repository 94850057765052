import React, {useState} from 'react';
import AnchorLink from "react-anchor-link-smooth-scroll";

const AnchorLinks = props => {

    return (
        <div className={['d-flex', props.align === 'left' ? 'justify-content-start' : 'justify-content-end'].join(' ')} style={{height: '50px'}}>
            <AnchorLink href='#medical-record'>
                <div className={['anchor-link-wrapper ', props.medicalRecord ? 'enabled' : 'disabled'].join(' ')}>
                    <div className='anchor-link medical-record'></div>
                </div>
            </AnchorLink>
            <AnchorLink href='#calendar'>
                <div className={['anchor-link-wrapper ', props.calendar ? 'enabled' : 'disabled'].join(' ')}>
                    <div className='anchor-link calendar'></div>
                </div>
            </AnchorLink>
            <AnchorLink href='#chat'>
                <div className={['anchor-link-wrapper ', props.chat ? 'enabled' : 'disabled'].join(' ')}>
                    <div className='anchor-link chat'></div>
                </div>
            </AnchorLink>
            <AnchorLink href='#documents'>
                <div className={['anchor-link-wrapper ', props.documents ? 'enabled' : 'disabled'].join(' ')}>
                    <div className='anchor-link documents'></div>
                </div>
            </AnchorLink>
            <AnchorLink href='#security'>
                <div className={['anchor-link-wrapper ', props.security ? 'enabled' : 'disabled'].join(' ')}>
                    <div className='anchor-link security'></div>
                </div>
            </AnchorLink>
        </div>
    )
}

export default AnchorLinks;