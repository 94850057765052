import React, {useEffect, useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./header/header";
import About from "./about/about";
import Resource from "./resource/resource";
import MedicalRecord from "./medicalRecord/medicalRecord";
import Calendar from "./calendar/calendar";
import Chat from "./chat/chat";
import Documents from "./documents/documents";
import Security from "./security/security";
import Plans from "./plans/plans";
import CookieModal from "./cookieModal/cookieModal";
import Footer from "./footer/footer";
import PwaInstall from "./pwaInstall/pwaInstall";

const Home = props => {
    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        localStorage.removeItem('session');
        if (location.search.includes("invalid-link")) {
            toast.error('Link para pagamento inválido.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => location.search = "", 4100);
        }

    }, []);

    const isDarkHandler = e => {
        if (screen.availWidth > 500 && e.target.className.includes('header-content')) {
            setIsDark(!isDark);
        }
    }
    return (
        <div className={["home", isDark ? 'dark' : ''].join(' ')}>
            <Header email={props.email} emailChangeHandler={props.emailChangeHandler} isDarkHandler={isDarkHandler}/>
            <About/>
            <Resource/>
            <MedicalRecord isDark={isDark}/>
            <Calendar isDark={isDark}/>
            <Chat isDark={isDark}/>
            <Documents isDark={isDark}/>
            <Security isDark={isDark}/>
            <Plans/>
            <Footer/>
            <ToastContainer />
            <PwaInstall/>
            <CookieModal/>
        </div>
    );
}

export default Home