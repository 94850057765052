import React, {useState} from "react";
import AnchorLinks from "../../../ui/AnchorLinks/anchorLinks";

const MedicalRecord = props => {
    const [medicalRecordImg] = useState('/assets/web/v1/images/medical-record-home-img.svg');
    const [prontuarioIcon] = useState('/assets/web/v1/icones/prontuario-icon-0.svg');
    const [prontuarioIconDark] = useState('/assets/web/v1/icones/prontuario-icon-0-dark.svg');

    return (
        <div className='medical-record' id='medical-record'>
            <div className='home-grid image-right home-grid-lg'>
                <div className='content'>
                    <p className='info'><img src={props.isDark ? prontuarioIconDark : prontuarioIcon} align='Prontuário' width={50}/> Prontuário Eletrônico</p>
                    <h2>Recursos que auxiliam para um<br/> melhor atendimento!</h2>
                    <p className='content-paragraph'>Todas as informações de seus clientes e pacientes serão salvas de
                        forma organizada na linha do tempo, contando com os respectivos arquivos e documentos, tudo de
                        forma online e segura. Você ainda pode contar com o gerenciamento do tempo de atendimento e de
                        espera de seu cliente ou paciente.</p>
                    <AnchorLinks
                        medicalRecord={false}
                        calendar={true}
                        chat={true}
                        documents={true}
                        security={true}
                        align='left'
                    />
                </div>
                <img src={medicalRecordImg} align='Prontuário'/>
            </div>
            <div className='home-grid home-grid-sm'>
                <img src='/assets/web/v1/images/medical-record-home-img-sm.svg' />
                <p className='info'>Prontuário Eletrônico</p>
                <h2>Recursos que auxiliam para um melhor atendimento!</h2>
                <p className='content-paragraph'>
                    Todas as informações de seus clientes e pacientes serão salvas de forma organizada na linha do tempo,
                    contando com os respectivos arquivos e documentos, tudo de forma online e segura. Você ainda pode
                    contar com o gerenciamento do tempo de atendimento e de espera do
                    seu cliente ou paciente.
                </p>
                <div className='anchor-links'>
                    <AnchorLinks
                        medicalRecord={false}
                        calendar={true}
                        chat={true}
                        documents={true}
                        security={true}
                        align='left'
                    />
                </div>
            </div>
        </div>
    )
}

export default MedicalRecord