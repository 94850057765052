import React, {useState} from "react";
import {Link} from "react-router-dom";

const Plans = props => {
    const [blueCheck] = useState('/assets/web/v1/icones/blue-check.svg')
    const [greyCheck] = useState('/assets/web/v1/icones/grey-check.svg')

    return (
        <div className='plans' id='plans'>
            <h2>Planos</h2>
            <p>Todos os planos são isentos de fidelidade, podendo ser cancelados a qualquer momento.</p>
            <div className='plans-grid plan-lg'>
                <div className='plan-item'>

                    <div className='free'>
                        <div className='plan-card plan-free'>
                            <h3><span style={{
                                fontSize: '12px',
                                color: '#333333',
                                fontWeight: 'normal',
                                height: '16px'
                            }}>Plano</span><br/>Básico</h3>
                            <p className='price'>R$ 0<span className='price-sm'>/mês</span></p>
                            <p className='info'>Por Profissional<br/><br/></p>
                            <Link to='/solicitar-convite'>
                                <button className='btn plan-cta'>Adquirir</button>
                            </Link>
                        </div>
                    </div>

                    <div className='plan-benefits'>
                        <p><b>Plano Básico:</b></p>
                        <ul>
                            <li><img src={greyCheck} alt='check'/> Perfil Profissional online</li>
                            <li><img src={greyCheck} alt='check'/> Agendamento online</li>
                            <li><img src={greyCheck} alt='check'/> Agenda unificada</li>
                            <li><img src={greyCheck} alt='check'/> Chat integrado</li>
                            <li><img src={greyCheck} alt='check'/> Prontuário eletrônico limitado*</li>
                            <li><img src={greyCheck} alt='check'/> Gestão de pacientes</li>
                            <li><img src={greyCheck} alt='check'/> E-mails comemorativos</li>
                            <li style={{color: '#7C8190', fontSize: '12px'}}>*Sem anexos e com limite para até 100
                                pacientes
                            </li>
                        </ul>
                    </div>
                </div>


                <div className='plan-item'>
                    <div className='paid'>
                        <div className='plan-card plan-paid'>
                            <h3><span style={{
                                fontSize: '12px',
                                color: '#FBFCFF',
                                fontWeight: 'normal',
                                height: '16px'
                            }}>Plano</span><br/>Completo</h3>
                            <p className='price'>R$ 149<span className='price-sm'>/mês</span></p>
                            <p className='info'>Por Profissional<br/>pago mensalmente</p>
                            <Link to='/solicitar-convite'>
                                <button className='btn plan-cta'>Adquirir</button>
                            </Link>
                        </div>
                        {/*<div className='plan-card plan-paid'>*/}
                        {/*    <p className='plan-promo'>PROMOÇÃO PLANO COMPLETO</p>*/}
                        {/*    <p className='plan-offer-full'>De: <span className='price-cut'><b>R$ 149,00</b>/mês</span></p>*/}
                        {/*    <p className='plan-offer-discount'>Por: <span className='price'>R$  74,50</span>/mês</p>*/}
                        {/*    <p className='plan-offer-info'>Pago por profissional durante<br/> todo o ano de 2021.</p>*/}
                        {/*    <Link to='/solicitar-convite'>*/}
                        {/*        <button className='btn plan-cta btn-promo'>Adquirir</button>*/}
                        {/*    </Link>*/}
                        {/*    <p className='plan-offer-valid'>Oferta válida até o dia 31/12/20</p>*/}
                        {/*</div>*/}
                    </div>
                    <div className='plan-benefits'>
                        <p><b>Plano Completo:</b></p>
                        <ul>
                            <li><img src={blueCheck} alt='check'/> Perfil Profissional online</li>
                            <li><img src={blueCheck} alt='check'/> Agendamento online</li>
                            <li><img src={blueCheck} alt='check'/> Agenda unificada</li>
                            <li><img src={blueCheck} alt='check'/> Chat integrado</li>
                            <li><img src={blueCheck} alt='check'/> Prontuário eletrônico ilimitado</li>
                            <li><img src={blueCheck} alt='check'/> Gestão de pacientes</li>
                            <li><img src={blueCheck} alt='check'/> E-mails comemorativos</li>
                            <li><img src={blueCheck} alt='check'/> Receituários (Normal, Especial e Guias de Exames)</li>
                            <li><img src={blueCheck} alt='check'/> Gestão de serviços e produtos</li>
                            <li><img src={blueCheck} alt='check'/> Gestão de orçamentos</li>
                            <li><img src={blueCheck} alt='check'/> Gestão de contratos/termos</li>
                            <li><img src={blueCheck} alt='check'/> Gestão de licenças</li>
                            <li><img src={blueCheck} alt='check'/> Assinatura digital</li>
                        </ul>
                    </div>
                </div>


                <div className='plan-item'>
                    <div className='custom'>
                        <div className='plan-card plan-custom'>
                            <h3><br/><br/></h3>
                            <p className='price'>Em breve!</p>
                            <p className='info'>Aguarde novas<br/>modalidades e recursos</p>
                        </div>
                    </div>
                    <div className='plan-benefits'>
                        <p><b>Em Breve:</b></p>
                        <p>Estaremos ofertando novas formas para personalizar sua experiências com novos recursos
                            desenvolvidos especialmente para você.</p>
                    </div>
                </div>
            </div>

            <div className='plans-grid plan-sm'>
                <div className='plan-item'>
                    <div className='paid'>
                        <div className='plan-card plan-paid'>
                            <h3><span style={{
                                fontSize: '12px',
                                color: '#FBFCFF',
                                fontWeight: 'normal',
                                height: '16px'
                            }}>Plano</span><br/>Completo</h3>
                            <p className='price'>R$ 149<span className='price-sm'>/mês</span></p>
                            <p className='info'>Por Profissional<br/>pago mensalmente</p>
                            <Link to='/solicitar-convite'>
                                <button className='btn plan-cta'>Adquirir</button>
                            </Link>
                        </div>
                        {/*<div className='plan-card plan-paid'>*/}
                        {/*    <p className='plan-promo'>PROMOÇÃO PLANO COMPLETO</p>*/}
                        {/*    <p className='plan-offer-full'>De: <span className='price-cut'><b>R$ 149,00</b>/mês</span></p>*/}
                        {/*    <p className='plan-offer-discount'>Por: <span className='price'>R$  74,50</span>/mês</p>*/}
                        {/*    <p className='plan-offer-info'>Pago por profissional durante<br/> todo o ano de 2021.</p>*/}
                        {/*    <Link to='/solicitar-convite'>*/}
                        {/*        <button className='btn plan-cta btn-promo'>Adquirir</button>*/}
                        {/*    </Link>*/}
                        {/*    <p className='plan-offer-valid'>Oferta válida até o dia 31/12/20</p>*/}
                        {/*</div>*/}
                    </div>
                    <div className='plan-benefits'>
                        <p data-toggle="collapse"
                           data-target="#collapse-items-paid"><b>ver todos os recursos</b></p>
                        <div className="collapse show" id="collapse-items-paid">
                            <ul>
                                <li><img src={blueCheck} alt='check'/> Perfil Profissional online</li>
                                <li><img src={blueCheck} alt='check'/> Agendamento online</li>
                                <li><img src={blueCheck} alt='check'/> Agenda unificada</li>
                                <li><img src={blueCheck} alt='check'/> Chat integrado</li>
                                <li><img src={blueCheck} alt='check'/> Prontuário eletrônico ilimitado</li>
                                <li><img src={blueCheck} alt='check'/> Gestão de pacientes</li>
                                <li><img src={blueCheck} alt='check'/> E-mails comemorativos</li>
                                <li><img src={blueCheck} alt='check'/> Receituários (Normal, Especial e Guias de Exames)</li>
                                <li><img src={blueCheck} alt='check'/> Gestão de serviços e produtos</li>
                                <li><img src={blueCheck} alt='check'/> Gestão de orçamentos</li>
                                <li><img src={blueCheck} alt='check'/> Gestão de contratos/termos</li>
                                <li><img src={blueCheck} alt='check'/> Gestão de licenças</li>
                                <li><img src={blueCheck} alt='check'/> Assinatura digital</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='plan-item'>

                    <div className='free'>
                        <div className='plan-card plan-free'>
                            <h3><span style={{
                                fontSize: '12px',
                                color: '#333333',
                                fontWeight: 'normal',
                                height: '16px'
                            }}>Plano</span><br/>Básico</h3>
                            <p className='price'>R$ 0<span className='price-sm'>/mês</span></p>
                            <p className='info'>Por Profissional<br/><br/></p>
                            <Link to='/solicitar-convite'>
                                <button className='btn plan-cta'>Adquirir</button>
                            </Link>
                        </div>
                    </div>

                    <div className='plan-benefits'>
                        <p data-toggle="collapse"
                           data-target="#collapse-items-free"><b>ver todos os recursos</b></p>
                        <div className="collapse" id="collapse-items-free">
                            <ul>
                                <li><img src={greyCheck} alt='check'/> Perfil Profissional online</li>
                                <li><img src={greyCheck} alt='check'/> Agendamento online</li>
                                <li><img src={greyCheck} alt='check'/> Agenda unificada</li>
                                <li><img src={greyCheck} alt='check'/> Chat integrado</li>
                                <li><img src={greyCheck} alt='check'/> Prontuário eletrônico limitado*</li>
                                <li><img src={greyCheck} alt='check'/> Gestão de pacientes</li>
                                <li><img src={greyCheck} alt='check'/> E-mails comemorativos</li>
                                <li style={{color: '#7C8190', fontSize: '12px'}}>*Sem anexos e com limite para até 100
                                    pacientes
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='plan-item'>
                    <div className='custom'>
                        <div className='plan-card plan-custom'>
                            <h3><br/><br/></h3>
                            <p className='price'>Em breve!</p>
                            <p className='info'>Aguarde novas<br/>modalidades e recursos</p>
                        </div>
                    </div>
                    <div className='plan-benefits'>
                        <p><b>Em Breve:</b></p>
                        <p>Estaremos ofertando novas formas para personalizar sua experiências com novos recursos
                            desenvolvidos especialmente para você.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans;