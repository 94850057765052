import React, {useState} from "react";
import AnchorLinks from "../../../ui/AnchorLinks/anchorLinks";


const Calendar = props => {
    const [calendarImg] = useState('/assets/web/v1/images/calendar-home-img.svg');
    const [calendarIcon] = useState('/assets/web/v1/icones/calendar-icon-0.svg');
    const [calendarIconDark] = useState('/assets/web/v1/icones/calendar-icon-0-dark.svg');
    return (
        <div className='calendar' id='calendar'>
            <div className='home-grid image-left home-grid-lg'>
                <img src={calendarImg} alt='Agenda'/>
                <div className='content'>
                    <p className='info'>Agenda <img src={props.isDark ? calendarIconDark : calendarIcon} alt='Agenda' width={50}/></p>
                    <h2>Planeje e organize seus<br/> atendimentos com indicadores!</h2>
                    <p className='content-paragraph'>Com uma agenda integrada e podendo gerenciar múltiplos
                        consultórios, seus atendimetos e procedimentos podem ser acessados em um único local,
                        facilitando assim a gestão do seu tempo.
                        A agenda, ainda disponibiliza indicadores necessários para analisar a performance do seu
                        atendimento.</p>
                    <AnchorLinks
                        medicalRecord={true}
                        calendar={false}
                        chat={true}
                        documents={true}
                        security={true}
                        align='right'
                    />
                </div>
            </div>
            <div className='home-grid home-grid-sm'>
                <img src='/assets/web/v1/images/calendar-home-img-sm.svg' />
                <p className='info'>Agenda</p>
                <h2>Planeje e organize seus atendimentos com indicadores!</h2>
                <p className='content-paragraph'>
                    Com uma agenda integrada e podendo gerenciar múltiplos consultórios,  seus atendimetos e procedimentos podem ser acessados em um único local, facilitando assim a gestão do seu tempo.
                    A agenda ainda disponibiliza indicadores necessários para analisar a performance do seu atendimento.
                </p>
                <div className='anchor-links'>
                    <AnchorLinks
                        medicalRecord={true}
                        calendar={false}
                        chat={true}
                        documents={true}
                        security={true}
                        align='right'
                    />
                </div>
            </div>
        </div>
    )
}

export default Calendar