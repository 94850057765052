import React, {useState} from "react";
import MyDrawer from "../../../../ui/drawer/Drawer";
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Navigation = (props) => {
    const [houmedLogo] = useState("https://houmed-public.s3.amazonaws.com/identidade-visual/Logotipo+Colorido.png");
    const [professionalIcon] = useState("/assets/web/v1/icones/professional-icon.svg");
    const [patientIcon] = useState("/assets/web/v1/icones/patient-icon.svg");

    return (
        <nav className="navigation">
            <a href='/'><img className='header-logo' src={houmedLogo} alt="Houmed"/></a>
            <MyDrawer/>
            <ul>
                <li>
                    <AnchorLink href="#resource">Funcionalidades</AnchorLink>
                </li>
                <li>
                    <AnchorLink href="#plans">Planos</AnchorLink>
                </li>
                <li>
                    <div className="dropdown">
                        <button
                            className="btn doc-primary-btn"
                            type="button"
                            id="access-button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Acessar
                        </button>
                        <div
                            className="dropdown-menu doc-custom-dropdown"
                            aria-labelledby="dropdownMenu2"
                        >
                            <a href='/profissionais'>
                                <button className="dropdown-item" type="button">
                                    <img
                                        src={professionalIcon}
                                        alt="Profissionais"
                                        style={{marginRight: "6px"}}
                                    />{" "}
                                    Profissionais
                                </button>
                            </a>
                            <a href='/usuarios'>
                                <button className="dropdown-item" type="button">
                                    <img
                                        src={patientIcon}
                                        alt="Pacientes"
                                        style={{marginRight: "6px"}}
                                    />{" "}
                                    Pacientes
                                </button>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    );
};

export default Navigation;


