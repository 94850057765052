import React, {useEffect, useState} from "react";

const CookieModal = props => {

    const [showModal, setShowModal] = useState(false);

    function acceptCookie() {
        if (document.cookie.indexOf('cookie_notice_accepted') === -1) {
            var d = new Date();
            d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
            document.cookie = 'cookie_notice_accepted=true' + ';expires=' + d.toUTCString();
            setShowModal(false);
        } else {
            setShowModal(false);
        }
    }

    useEffect(() => {
        if (document.cookie.indexOf('cookie_notice_accepted') === -1) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, []);


    return showModal ?
        <div className='cookie-modal' id='cookie-modal'>
            <p className="modal-cookie-text">Este website usa cookies. Se estiver de acordo, continue
                navegando!</p>
            <button className="btn modal-cookie-button" type="button" id="modal-cookie-buttom"
                    onClick={acceptCookie}>OK
            </button>
        </div> : null
}

export default CookieModal;