import React, {useState} from "react";
import AnchorLinks from "../../../ui/AnchorLinks/anchorLinks";

const Documents = props => {
    const [documentsImg] = useState('/assets/web/v1/images/documents-home-img.svg');
    const [documentsIcon] = useState('/assets/web/v1/icones/documentos-icon-0.svg');
    const [documentsIconDark] = useState('/assets/web/v1/icones/documentos-icon-0-dark.svg');
    return (
        <div className='documents' id='documents'>
            <div className='home-grid image-left home-grid-lg'>
                <img src={documentsImg} alt='Módulo Documentos'/>
                <div className='content'>
                    <p className='info'>Módulo Documentos <img src={props.isDark ? documentsIconDark : documentsIcon} alt='Módulo Documentos'/></p>
                    <h2>Documentos elaborados e<br/> automaticamente organizados!</h2>
                    <p className='content-paragraph'>Viabilize o seu tempo com a gestão de seus principais documentos
                        utilizados diariamente, ex: receituários, orçamentos, termos e contratos. Todos eles podendo ser
                        elaborados e organizados de maneira intuitiva facilitando assim, o acesso e a edição dos
                        mesmos.</p>
                    <AnchorLinks
                        medicalRecord={true}
                        calendar={true}
                        chat={true}
                        documents={false}
                        security={true}
                        align='right'
                    />
                </div>
            </div>
            <div className='home-grid home-grid-sm'>
                <img src='/assets/web/v1/images/documents-home-img-sm.svg' />
                <p className='info'>Módulo Documentos</p>
                <h2>Documentos elaborados e automaticamente organizados!</h2>
                <p className='content-paragraph'>
                    Viabilize o seu tempo com a gestão de seus principais documentos utilizados diariamente.
                    Ex: receituários, orçamentos, termos e contratos. Todos eles podem ser elaborados e organizados
                    de maneira intuitiva facilitando assim, o acesso e a edição dos mesmos.
                </p>
                <div className='anchor-links'>
                    <AnchorLinks
                        medicalRecord={true}
                        calendar={true}
                        chat={true}
                        documents={false}
                        security={true}
                        align='right'
                    />
                </div>
            </div>
        </div>
    )
}

export default Documents